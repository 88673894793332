import React from 'react';
import {ConfigProvider} from "antd";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {BrowserRouter} from "react-router-dom";
import './i18n/config';

import RootRouter from "./routing/root/RootRouter";

import './styles/main.scss'

const queryClient = new QueryClient()

function App() {
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#00A389'
                }
            }}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <RootRouter/>
                </BrowserRouter>
            </QueryClientProvider>
        </ConfigProvider>
    );
}

export default App;
