import React from 'react';

function PhoneIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.4932 14.3332C22.9198 14.3332 22.4665 13.8666 22.4665 13.3066C22.4665 12.8132 21.9732 11.7866 21.1465 10.8932C20.3332 10.0266 19.4398 9.51989 18.6932 9.51989C18.1198 9.51989 17.6665 9.05322 17.6665 8.49322C17.6665 7.93322 18.1332 7.46655 18.6932 7.46655C20.0265 7.46655 21.4265 8.18655 22.6532 9.47989C23.7998 10.6932 24.5332 12.1999 24.5332 13.2932C24.5332 13.8666 24.0665 14.3332 23.4932 14.3332Z"
                fill="white"/>
            <path
                d="M28.3068 14.3334C27.7335 14.3334 27.2802 13.8667 27.2802 13.3067C27.2802 8.57341 23.4268 4.73341 18.7068 4.73341C18.1335 4.73341 17.6802 4.26675 17.6802 3.70675C17.6802 3.14675 18.1335 2.66675 18.6935 2.66675C24.5602 2.66675 29.3335 7.44008 29.3335 13.3067C29.3335 13.8667 28.8668 14.3334 28.3068 14.3334Z"
                fill="white"/>
            <path
                d="M14.7332 19.9334L12.2665 22.4001C11.7465 22.9201 10.9198 22.9201 10.3865 22.4134C10.2398 22.2667 10.0932 22.1334 9.9465 21.9867C8.57317 20.6001 7.33317 19.1467 6.2265 17.6267C5.13317 16.1067 4.25317 14.5867 3.61317 13.0801C2.9865 11.5601 2.6665 10.1067 2.6665 8.72008C2.6665 7.81341 2.8265 6.94675 3.1465 6.14675C3.4665 5.33342 3.97317 4.58675 4.67984 3.92008C5.53317 3.08008 6.4665 2.66675 7.45317 2.66675C7.8265 2.66675 8.19984 2.74675 8.53317 2.90675C8.87984 3.06675 9.1865 3.30675 9.4265 3.65341L12.5198 8.01342C12.7598 8.34675 12.9332 8.65341 13.0532 8.94675C13.1732 9.22675 13.2398 9.50675 13.2398 9.76008C13.2398 10.0801 13.1465 10.4001 12.9598 10.7067C12.7865 11.0134 12.5332 11.3334 12.2132 11.6534L11.1998 12.7067C11.0532 12.8534 10.9865 13.0267 10.9865 13.2401C10.9865 13.3467 10.9998 13.4401 11.0265 13.5467C11.0665 13.6534 11.1065 13.7334 11.1332 13.8134C11.3732 14.2534 11.7865 14.8267 12.3732 15.5201C12.9732 16.2134 13.6132 16.9201 14.3065 17.6267C14.4398 17.7601 14.5865 17.8934 14.7198 18.0267C15.2532 18.5467 15.2665 19.4001 14.7332 19.9334Z"
                fill="white"/>
            <path
                d="M29.2931 24.44C29.2931 24.8133 29.2265 25.2 29.0931 25.5733C29.0531 25.68 29.0131 25.7866 28.9598 25.8933C28.7331 26.3733 28.4398 26.8266 28.0531 27.2533C27.3998 27.9733 26.6798 28.4933 25.8665 28.8266C25.8531 28.8266 25.8398 28.84 25.8265 28.84C25.0398 29.16 24.1865 29.3333 23.2665 29.3333C21.9065 29.3333 20.4531 29.0133 18.9198 28.36C17.3865 27.7066 15.8531 26.8266 14.3331 25.72C13.8131 25.3333 13.2931 24.9466 12.7998 24.5333L17.1598 20.1733C17.5331 20.4533 17.8665 20.6666 18.1465 20.8133C18.2131 20.84 18.2931 20.88 18.3865 20.92C18.4931 20.96 18.5998 20.9733 18.7198 20.9733C18.9465 20.9733 19.1198 20.8933 19.2665 20.7466L20.2798 19.7466C20.6131 19.4133 20.9331 19.16 21.2398 19C21.5465 18.8133 21.8531 18.72 22.1865 18.72C22.4398 18.72 22.7065 18.7733 22.9998 18.8933C23.2931 19.0133 23.5998 19.1866 23.9331 19.4133L28.3465 22.5466C28.6931 22.7866 28.9331 23.0666 29.0798 23.4C29.2131 23.7333 29.2931 24.0666 29.2931 24.44Z"
                fill="white"/>
        </svg>

    );
}

export default PhoneIcon;