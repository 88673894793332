import React from 'react';

function MessageIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M26.6667 9.00008C28.6917 9.00008 30.3333 7.35846 30.3333 5.33341C30.3333 3.30837 28.6917 1.66675 26.6667 1.66675C24.6416 1.66675 23 3.30837 23 5.33341C23 7.35846 24.6416 9.00008 26.6667 9.00008Z" fill="white"/>
            <path d="M25.3865 10.8667C23.2932 10.4134 21.5865 8.70675 21.1332 6.61341C20.9598 5.80008 20.9465 5.01341 21.0932 4.26675C21.2665 3.44008 20.6532 2.66675 19.7998 2.66675H9.33317C5.65317 2.66675 2.6665 5.65341 2.6665 9.33341V18.6001C2.6665 22.2801 5.65317 25.2667 9.33317 25.2667H11.3332C11.7065 25.2667 12.1865 25.5067 12.3998 25.8001L14.3998 28.4534C15.2798 29.6267 16.7198 29.6267 17.5998 28.4534L19.5998 25.8001C19.8532 25.4667 20.2398 25.2667 20.6665 25.2667H22.6798C26.3598 25.2667 29.3332 22.2934 29.3332 18.6134V12.2001C29.3332 11.3601 28.5598 10.7467 27.7332 10.9067C26.9865 11.0401 26.1998 11.0401 25.3865 10.8667ZM10.6665 16.0001C9.91984 16.0001 9.33317 15.4001 9.33317 14.6667C9.33317 13.9334 9.91984 13.3334 10.6665 13.3334C11.3998 13.3334 11.9998 13.9334 11.9998 14.6667C11.9998 15.4001 11.4132 16.0001 10.6665 16.0001ZM15.9998 16.0001C15.2532 16.0001 14.6665 15.4001 14.6665 14.6667C14.6665 13.9334 15.2532 13.3334 15.9998 13.3334C16.7332 13.3334 17.3332 13.9334 17.3332 14.6667C17.3332 15.4001 16.7465 16.0001 15.9998 16.0001ZM21.3332 16.0001C20.5865 16.0001 19.9998 15.4001 19.9998 14.6667C19.9998 13.9334 20.5865 13.3334 21.3332 13.3334C22.0665 13.3334 22.6665 13.9334 22.6665 14.6667C22.6665 15.4001 22.0798 16.0001 21.3332 16.0001Z" fill="white"/>
        </svg>

    );
}

export default MessageIcon;