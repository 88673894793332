import React, {ReactNode} from 'react';
import Header from "./header/Header";
import Footer from "./footer/Footer";

// props
type Props = { children: ReactNode }

function MainLayout(props: Props) {
    return (
        <div>
            <Header/>
            <div>{props.children}</div>
            <Footer/>
        </div>
    );
}

export default MainLayout;