import React from 'react';

function TelegramIcon() {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.0202 3.94672L9.98016 7.94672C1.88682 10.6534 1.88682 15.0667 9.98016 17.7601L13.5535 18.9467L14.7402 22.5201C17.4335 30.6134 21.8602 30.6134 24.5535 22.5201L28.5668 10.4934C30.3535 5.09339 27.4202 2.14672 22.0202 3.94672ZM22.4468 11.1201L17.3802 16.2134C17.1802 16.4134 16.9268 16.5067 16.6735 16.5067C16.4202 16.5067 16.1668 16.4134 15.9668 16.2134C15.5802 15.8267 15.5802 15.1867 15.9668 14.8001L21.0335 9.70672C21.4202 9.32006 22.0602 9.32006 22.4468 9.70672C22.8335 10.0934 22.8335 10.7334 22.4468 11.1201Z"
                fill="white"/>
        </svg>

    );
}

export default TelegramIcon;