import {useQuery} from "@tanstack/react-query";
import {message} from "antd";

import {PostModel} from "../../../utils/postModel";
import $api from "../../../services/RequestService";
import {queryKeys} from "../utils/constant/queryKeys";
import {endPoints} from "../utils/constant/endPoints";

export function useGetPost(post_id: string | undefined) {
    return useQuery<PostModel>(
        [queryKeys.POST_VIEW, post_id],
        async () => {
            const res = await $api.get(`${endPoints.POST_VIEW}?post_id=${post_id}`);
            return res.data.posts[0]
        }, {
            onError: () => {
                message.error('Error')
            }
        }
    )
}

