import {useQuery} from "@tanstack/react-query";
import {CategoryShowModel} from "../../../utils/categoryShowModel";
import {queryKeys} from "../utils/constant/queryKeys";
import $api from "../../../services/RequestService";
import {endPoints} from "../utils/constant/endPoints";
import {message} from "antd";

export function useGetShowCategories(category_id: any) {
    return useQuery<CategoryShowModel[]>(
        [queryKeys.CATEGORY_VIEW, category_id],
        async () => {
            const res = await $api.get(`${endPoints.CATEGORY_VIEW}?id=${category_id}`);
            return res.data.posts
        },
        {
            onError: () => {
                message.error('Error')
            }
        }
    )
}