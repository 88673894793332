import React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useGetShowCategories} from "./services/queries";
import styles from './detailedCategory.module.scss'
import {Empty, Spin} from "antd";
import {rootPaths} from "../../routing/root/rootPaths";

function DetailedCategory() {
    const params = useParams();
    const {data, isLoading} = useGetShowCategories(params.category_id);
    const navigate = useNavigate()

    function detailedCategory(id: number) {
        navigate(`${rootPaths.DETAILED_CATEGORY}/${params.category_id}/${id}`)
    }


    return (
        <Spin spinning={isLoading}>
            <div className={styles.detailed_category}>
                <div className={styles.detailed_category_header}>
                    <h3 className={styles.detailed_category_header_title}>Uysot tuzulmasi</h3>
                    <p className={styles.detailed_category_header_description}>
                        Bizning platformamizning yuragi. Tuzulmalar bilan tanishib chiqing
                    </p>
                </div>
                <div className={styles.detailed_category_content}>
                    <div className={styles.detailed_category_content}>
                        {Array.isArray(data) && data.length === 0 ? (
                            <Empty/>
                        ) : (
                            data?.map((category) => (
                                <div key={category.id} onClick={() => detailedCategory(category.id)}>
                                    <h3 className={styles.detailed_category_content_item}>
                                        {category.name_uz.slice(0, 50)}
                                    </h3>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </Spin>
    );
}

export default DetailedCategory;
