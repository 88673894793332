import React from 'react';

function UysotLogo() {
    return (
        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M3.28223 28.0128C3.28223 26.54 4.48269 25.3461 5.96355 25.3461H25.6265C27.1074 25.3461 28.3079 26.54 28.3079 28.0128C28.3079 29.4856 27.1074 30.6795 25.6265 30.6795H5.96355C4.48269 30.6795 3.28223 29.4856 3.28223 28.0128Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.1144 1.11013C15.1558 0.0572851 16.8442 0.0572851 17.8856 1.11013L31.219 14.59C32.2603 15.6428 32.2603 17.3498 31.219 18.4027C30.1776 19.4555 28.4891 19.4555 27.4477 18.4027L16 6.82914L4.55228 18.4027C3.51089 19.4555 1.82245 19.4555 0.781049 18.4027C-0.26035 17.3498 -0.26035 15.6428 0.781049 14.59L14.1144 1.11013Z"
                  fill="white"/>
            <path
                d="M20.5129 18.1666C20.5129 20.5457 18.4925 22.4743 16.0001 22.4743C13.5078 22.4743 11.4873 20.5457 11.4873 18.1666C11.4873 15.7876 13.5078 13.8589 16.0001 13.8589C18.4925 13.8589 20.5129 15.7876 20.5129 18.1666Z"
                fill="#FFCF9E"/>
        </svg>

    );
}

export default UysotLogo;