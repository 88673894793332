import {useQuery} from "@tanstack/react-query";
import {message} from "antd";

import {endPoints} from "../utils/constant/endPoints";
import {queryKeys} from "../utils/constant/queryKeys";
import $api from "../../../services/RequestService";
import {CategoryModel} from "../../../utils/categoryModel";

export function useGetCategories() {
    return useQuery<CategoryModel[]>(
        [queryKeys.CATEGORY_SHOW],
        async () => {
            const res = await $api.get(endPoints.CATEGORY_SHOW);
            return res.data
        },
        {
            onError: () => {
                message.error('Error');
            }
        }
    );
}

