import React, {useMemo} from 'react';
import {useNavigate} from 'react-router-dom';

import ShareIcon from '../../../../assets/icons/ShareIcon';
import {CategoryModel} from '../../../../utils/categoryModel';
import {rootPaths} from '../../../../routing/root/rootPaths';

import styles from './categoryCard.module.scss';

const CategoryCard: React.FC<CategoryModel> = ({description_uz, name_uz, id}) => {
  const navigate = useNavigate();

  // navigate detailed-category
  function toDetailedCategory() {
    navigate(`${rootPaths.DETAILED_CATEGORY}/${id}`);
  }

  // description
  const description = useMemo(() => {
    if (description_uz.length > 90) {
      return description_uz.slice(0, 90) + '...'
    }
    return description_uz

  }, [])

  return (
      <div className={styles.category_card} onClick={toDetailedCategory}>
        <h3>{name_uz}</h3>
        <p>{description}</p>
        <ShareIcon/>
      </div>
  );
}

export default CategoryCard;
