import React from 'react';

function PrevIcon() {
    return (
        <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 7.27404L16.25 7.27404" stroke="#667085" strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M7.2998 13.2986L1.2498 7.2746L7.2998 1.2496" stroke="#667085" strokeWidth="2"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default PrevIcon;