import React from 'react';
import {notification, Button, Input} from "antd";
import {useTranslation} from 'react-i18next'


import TelegramIcon from "../../../assets/icons/TelegramIcon";

import UserAvatar from '../../../assets/image/UserAvatar.png'
import FirstLaptop from '../../../assets/image/FirstLaptop.png'
import SecondLaptop from '../../../assets/image/SecondLaptop.png'

import PhoneIcon from "../../../assets/icons/PhoneIcon";
import MessageIcon from "../../../assets/icons/MessageIcon";
import TelegramBotIcon from "../../../assets/icons/TelegramBotIcon";
import MailIcon from "../../../assets/icons/MailIcon";
import NextIcon from "../../../assets/icons/NextIcon";
import CallIcon from "../../../assets/icons/CallIcon";
import UysotLogo2 from "../../../assets/icons/UysotLogo2";

import styles from './footer.module.scss'

function Footer() {
  const {t, i18n} = useTranslation();
  const d = new Date()
  const [api, contextHolder] = notification.useNotification();
  type NotificationType = 'success'

  function handleChangeLanguage(lang: string) {
    i18n.changeLanguage(lang)
  }

  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: "Raqamingiz mutaxasisga yuborildi"
    });
  };

  return (
    <div className={styles.footer}>
      <div className={styles.footer_top}>
        <div className={styles.footer_top_top}>
          <img src={UserAvatar} alt=""/>
        </div>
        <div className={styles.footer_top_center}>
          <p>{t('COULDN\'T_FIND_ANSWERS_TO_YOUR_QUESTIONS?')}</p>
          <span>{t('SEND_YOUR_QUESTIONS_THROUGH_OUR_OFFICIAL_TELEGRAM_BOT_AND_GET_ANSWERS_RIGHT_AWAY')}</span>
        </div>
        <div className={styles.footer_top_bottom}>
          <a href="https://t.me/uysot_admin" target='_blank' rel='noopener'>
            <Button>
              <TelegramIcon/>
              <span>Telegram bot</span>
            </Button>
          </a>
        </div>
      </div>
      <div className={styles.footer_center}>
        <div className={styles.footer_center_left}>
          <img src={FirstLaptop} alt=""/>
        </div>
        <div className={styles.footer_center_center}>
         <div className={styles.phone_message}>
         <div className={styles.footer_center_center_left}>
            <div className={styles.phone}>
              <PhoneIcon/>
            </div>
            <div className={styles.top}>
              <CallIcon/>
              <a href="tel:+998 91 619 68 88">+998 91 619 68 88</a>
            </div>
            <div className={styles.top}>
              <CallIcon/>
              <a href="tel:+998 91 619 68 88">+998 91 619 68 88</a>
            </div>
          </div>
          <div className={styles.footer_center_center_center}>
            <div className={styles.message}>
              <MessageIcon/>
            </div>
            <div className={styles.top}>
              <TelegramBotIcon/>
              <a href='https://t.me/uysot_admin' target='_blank'  rel='noopener'>UYSOT ADMIN</a>
            </div>
            <div className={styles.bottom}>
              <MailIcon/>
              <a href='mailto:info@uysot.uz' target='_blank'>info@uysot.uz</a>
            </div>
          </div>
         </div>
          <div className={styles.footer_center_center_right}>
            <div className={styles.top}>
              <TelegramIcon/>
              <span>{t('GET_A_FREE_CONSULTATION')}</span>
            </div>
            <div className={styles.bottom}>
              <Input placeholder={t('LEAVE_A_PHONE_NUMBER') || undefined}></Input>
              <Button onClick={() => openNotificationWithIcon('success')}>
                <NextIcon/>
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.footer_center_right}>
          <img src={SecondLaptop} alt=""/>
        </div>
      </div>
      {/*<div className={styles.footer_bottom}>*/}
      {/*  <div className={styles.footer_bottom_left}>*/}
      {/*    <UysotLogo2/>*/}
      {/*    <span>Uysot</span>*/}
      {/*  </div>*/}
      {/*  <p>© Copyright {d.getFullYear()} Softex PE</p>*/}
      {/*</div>*/}
    </div>
  );
}

export default Footer;