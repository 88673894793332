import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {Button, Input} from 'antd';

import UysotLogo from '../../../assets/icons/UysotLogo';
import NextIcon from '../../../assets/icons/NextIcon';
import {rootPaths} from '../../../routing/root/rootPaths';

import styles from '../../../layouts/main-layout/header/header.module.scss';
import SearchIcon from '../../../assets/icons/SearchIcon';

function Header() {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();
    const [activeLang, setActiveLang] = useState('uz');

    // navigate home
    function toHome() {
        navigate(`${rootPaths.INDEX}`);
    }

    function handleChangeLanguage(lang: string) {
        i18n.changeLanguage(lang);
        setActiveLang(lang);
    }

    return (
        <div className={styles.header}>
            <div className={styles.header_top}>
                <div className={styles.header_top_left} onClick={toHome}>
                    <UysotLogo/>
                    <span>Uysot</span>
                </div>
                <div className={styles.header_top_right}>
                    <div className={styles.lang}>
                        <p
                            className={`${styles.langButton, styles.uz} ${activeLang === 'uz' && styles.active}`}
                            onClick={() => handleChangeLanguage('uz')}
                        >
                            Uz
                        </p>
                        <p
                            className={`${styles.langButton} ${activeLang === 'ru' && styles.active}`}
                            onClick={() => handleChangeLanguage('ru')}
                        >
                            Ru
                        </p>
                    </div>
                    <Button className={styles.program}>
                        <a href="https://app.uysot.uz/" target='_blank' rel='noopener'>
                        <span>{t('GO_TO_PROGRAM')}</span>
                        <NextIcon/>
                        </a>
                    </Button>
                </div>
            </div>
            <div className={styles.header_bottom}>
                <h3>Biz yordam berishga tayyormiz</h3>
                <p>Savol bering. Javob beramiz</p>
                <Input
                    placeholder={t('SEARCH') || undefined}
                    allowClear
                    style={{width: 320, height: 48}}
                    prefix={<SearchIcon/>}
                    className={styles.search}
                />
            </div>
        </div>
    );
}

export default Header;
