import React from 'react';
import {Spin} from "antd";

import CategoryCard from "./components/category-card/CategoryCard";
import {useGetCategories} from "../app/services/queries";
import FaqCollapse from "./components/faq-collapse/FaqCollapse";

import styles from './index.module.scss';

function Index() {
  const {data: categories, isLoading: categoriesIsLoading} = useGetCategories();

  return (
      <div className={styles.main}>
        <div className={styles.categories}>
          <div className={styles.header}>
            <h2>Uysot dasturi uchun qo’llanma </h2>
            <p>Qisqa videolar orqali dasturni yanada yaxshiroq o’rganing!</p>
          </div>
          <Spin spinning={categoriesIsLoading}>
            <div className={styles.body}>
              {categories?.map((item) => (
                  <CategoryCard key={item.id} {...item} />
              ))}
            </div>
          </Spin>
        </div>
        <div className={styles.faq}>
          <div className={styles.header}>
            <h2>Tez-tez beriladigan savollar</h2>
          </div>
          <div className={styles.body}>
            <FaqCollapse/>
          </div>
        </div>
      </div>
  );
}

export default Index;
