import React from 'react';

function ShareIcon() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 11L21.2 2.8" stroke="#B8B8B8" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M22.0002 6.8V2H17.2002" stroke="#B8B8B8" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13" stroke="#B8B8B8" strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
}

export default ShareIcon;