import React, {useEffect, useRef} from 'react';
import Plyr from 'plyr';
import Hls from 'hls.js';
import "plyr/dist/plyr.css";

interface CustomWindow extends Window {
  hls?: Hls;
}

type Props = {
  source: string | undefined
}

const Video: React.FC<Props> = ({source = ''}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const hlsRef = useRef<Hls | null>(null);

  useEffect(() => {
    const defaultOptions: Plyr.Options = {};

    if (!Hls.isSupported()) {
      if (videoRef.current) {
        videoRef.current.src = source;
        new Plyr(videoRef.current, defaultOptions);
      }
    } else {
      const hls = new Hls();
      hlsRef.current = hls;
      hls.loadSource(source);

      hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        const availableQualities = hls.levels.map((l) => l.height);
        availableQualities.unshift(0);

        defaultOptions.quality = {
          default: 0,
          options: availableQualities,
          forced: true,
          onChange: (e) => updateQuality(e),
        };

        defaultOptions.i18n = {
          qualityLabel: {
            0: 'Auto',
          },
        };

        hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
          const span = document.querySelector<HTMLSpanElement>(".plyr__menu__container [data-plyr='quality'][value='0'] span");
          if (hls.autoLevelEnabled) {
            span!.innerHTML = `Auto (${hls.levels[data.level].height}p)`;
          } else {
            span!.innerHTML = `Auto`;
          }
        });

        new Plyr(videoRef.current!, defaultOptions);
      });

      if (videoRef.current) {
        hls.attachMedia(videoRef.current);
        (window as CustomWindow).hls = hls;
      }
    }

    return () => {
      if (hlsRef.current) {
        hlsRef.current.destroy();
      }
    };
  }, [source]);

  function updateQuality(newQuality: number) {
    if (newQuality === 0) {
      (window as CustomWindow).hls!.currentLevel = -1;
    } else {
      (window as CustomWindow).hls!.levels.forEach((level, levelIndex) => {
        if (level.height === newQuality) {
          (window as CustomWindow).hls!.currentLevel = levelIndex;
        }
      });
    }
  }

  return <video ref={videoRef}/>;
};

export default Video;