import {useQuery} from "@tanstack/react-query";
import {FaqModel} from "../../../utils/faqModel";
import {queryKeys} from "../utils/constant/queryKeys";
import $api from "../../../services/RequestService";
import {endPoints} from "../utils/constant/endPoints";
import {message} from "antd";

export function useGetFaq() {
    return useQuery<FaqModel[]>(
        [queryKeys.FAQ_SHOW],
        async () => {
            const res = await $api.get(endPoints.FAQ_SHOW);
            return res.data;
        }, {
            onError: () => {
                message.error('Error')
            }
        }
    )
}