import React from 'react';
import {Route, Routes} from "react-router-dom";
import {rootRoutes} from "./rootRoutes";
import MainLayout from "../../layouts/main-layout/MainLayout";

function RootRouter() {
    return (
        <MainLayout>
            <Routes>
                {rootRoutes.map((item, index) => (
                    <Route path={item.path} element={<item.element/>} key={index}/>
                ))}
            </Routes>
        </MainLayout>
    );
}

export default RootRouter;