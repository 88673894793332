import axios, { AxiosRequestConfig } from 'axios';
import { LocalStorage } from "./LocalStorage";

export const baseURL = 'https://backend.help.uysot.uz/api/';

let isRefreshing = false
let failedQueue: any = []

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

const addToken = (config: AxiosRequestConfig) => {
  const token = LocalStorage.get('token')
  if (token) {
    config.headers!.Authorization = `Bearer ${token}`
  }
  return config
}

const resentRequest = async (err: any) => {
  const originalRequest = err?.config
  if (err.response.status === 401 && !originalRequest._retry && !isRefreshing) {
    // if (!LocalStorage.get('token')) {
    //   LocalStorage.set('redirect_url', window.location.href)
    //   // window.location.replace(window.location.origin)
    // }
    isRefreshing = true
    // if (isRefreshing) {
    //   // If I'm refreshing the token I send request to a queue
    //   return new Promise((resolve, reject) => {
    //     failedQueue.push({ resolve, reject })
    //   })
    //     .then((token) => {
    //       originalRequest.headers.Authorization = `Bearer ${token}`
    //       return axios(originalRequest)
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // }
    //
    // // If header of the request has changed, it means I've refreshed the token
    // const token = LocalStorage.get('token')
    //   console.log('working', err.config.Authorization)
    // if (originalRequest.headers.Authorization !== `Bearer ${token}`) {
    //   originalRequest.headers.Authorization = `Bearer ${token}`
    //   return Promise.resolve(axios(originalRequest))
    // }
    // originalRequest._retry = true // mark request a retry
    // isRefreshing = true // set the refreshing var to true

    // If none of the above, refresh the token and process the queue
    if (isRefreshing) {

      // isRefreshing = false
      return new Promise((resolve, reject) => {
        axios
          .get('https://service.app.uysot.uz/v1/auth/refresh', {
            withCredentials: true,
          }).then((response) => {
            isRefreshing = true
            LocalStorage.set('token', response.data?.data?.accessToken);
            window.location.reload()
          }).catch(() => {
            isRefreshing = true
            window.location.replace("https://app.uysot.uz")
          }) // The method that refreshes my token
        // .then((response) => {
        //   const user = LocalStorage.get('user')
        //   let isEqual = true
        //   if (typeof user === 'object') {
        //     if (
        //         user?.role?.permissions.length !==
        //         response.data?.data?.role?.permissions.length
        //     ) {
        //       isEqual = false
        //     }
        //     user?.role?.permissions.forEach((permission: any) => {
        //       if (
        //           response.data?.data?.role?.permissions.indexOf(permission) ===
        //           -1
        //       ) {
        //         isEqual = false
        //       }
        //     })
        //   }
        //   LocalStorage.set('token', response.data?.data?.accessToken) // The method that sets my token to localstorage/Redux/whatever
        //   processQueue(null, response.data?.data?.accessToken) // Resolve queued
        //   if (!isEqual) {
        //     LocalStorage.set('user', JSON.stringify(response.data?.data))
        //     window.location.reload()
        //   }
        //   originalRequest.headers.Authorization = `Bearer ${response.data?.data?.accessToken}`
        //   resolve(axios(originalRequest)) // Resolve current
        // })
        // .catch((err) => {
        //   LocalStorage.remove('token') // The method that removes my token from localstorage/Redux/whatever
        //   LocalStorage.remove('user')
        //   LocalStorage.set('redirect_url', window.location.pathname)
        //   window.location.replace('https://app.uysot.uz')
        //   processQueue(err, null)
        //   reject(err)
        // })
        // .finally(() => {
        //   isRefreshing = false
        // })
      })
    }
  }
  throw err
}

const $api = axios.create({
  baseURL,
  withCredentials: true,
});

$api.interceptors.request.use(addToken)

$api.interceptors.response.use(undefined, resentRequest)


export default $api;
