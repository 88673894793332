import React from 'react';

function NextIcon() {
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 8.5H13.5" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M9 4L13.5 8.5L9 13" stroke="#00A389" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default NextIcon;