import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import commonRu from './ru/common.json';
import commonUz from './uz/common.json'

export const resources = {
    uz: {
        translation: commonUz
    },
    ru: {
        translation: commonRu
    }
}

i18next.use(initReactI18next).init({
    lng: 'uz',
    resources,
});