import React from 'react';
import {Spin} from "antd";
import {sanitize} from "dompurify";
import {useNavigate, useParams} from "react-router-dom";

import {useGetPost} from "./services/queries";
import {useGetCategories} from "../app/services/queries";
import {rootPaths} from "../../routing/root/rootPaths";
import PrevIcon from "../../assets/icons/PrevIcon";
import Video from "./components/video/Video";

import styles from './detailedPost.module.scss'


function DetailedPost() {
  const params = useParams();
  const {data: post, isLoading: postIsLoading} = useGetPost(params.post_id);
  const {data: categories, isLoading: categoriesIsLoading} = useGetCategories();
  const navigate = useNavigate()

  // navigate detailed category + id
  function toDetailedCategory(categoryId: number) {
    navigate(`${rootPaths.DETAILED_CATEGORY}/${categoryId}`);
  }

  function prev() {
    navigate(-1)
  }

  return (
    <div className={styles.detailed_post}>
      <Spin spinning={categoriesIsLoading} wrapperClassName={styles.left_spin}>
        <div className={styles.detailed_post_left}>
          <h3>Categories</h3>
          <div className={styles.item}>
            {categories?.map((item) => (
              <p key={item.id} onClick={() => toDetailedCategory(item.id)}>
                {item.name_uz}
              </p>
            ))}
          </div>
        </div>
      </Spin>
      <Spin wrapperClassName={styles.right_spin} spinning={postIsLoading}>
        <div className={styles.detailed_post_right}>
          <div className={styles.header}>
            <div onClick={prev}>
              <PrevIcon/>
            </div>
            <h3>{post?.name_uz}</h3>
          </div>
          <div className={styles.player}>
            <Video source={post?.videouz_path}/>
          </div>
          <div dangerouslySetInnerHTML={{__html: sanitize(post?.html_uz ?? '')}}></div>
        </div>
      </Spin>
    </div>
  );
}

export default DetailedPost;

