export const LocalStorage = {
    get<T = any>(key: string): T | null | string {
        const item = localStorage.getItem(key)
        try {
            if (item) {
                return JSON.parse(item)
            }
            return null
        } catch (e) {
            return item
        }
    },
    set(key: string, value: string): void {
        localStorage.setItem(key, value)
    },
    remove(key: string) {
        localStorage.removeItem(key)
    },
    clear() {
        localStorage.clear()
    },
}
