import React from 'react';
import {Collapse} from 'antd';

import {useGetFaq} from "../../services/queries";
import PlusCircle from "../../../../assets/icons/PlusCircle";
import MinusCircle from "../../../../assets/icons/MinusCircle";

import styles from './faqCollapse.module.scss'

const {Panel} = Collapse;


function Faq() {
  const {data} = useGetFaq()


  return (
    <Collapse
      bordered={false}
      expandIconPosition={"end"}
      expandIcon={(panelProps) => panelProps.isActive ? <MinusCircle/> : <PlusCircle/>}
    >
      {data?.map((item) => (
        <Panel header={item?.question_uz} key={item?.id} className={styles.panel}>
          <p className={styles.answer}>{item?.answer_uz}</p>
        </Panel>
      ))}
    </Collapse>
  );
}

export default Faq;